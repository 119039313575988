<template>
  <v-container v-if="user.loggedIn">
    <v-row justify="center" class="text-center mt-4">
      <v-col cols="12" md="10" lg="9">
        <v-avatar size="75"><v-img :src="user.person.avatar"></v-img></v-avatar>
        <h2 class="mt-2">{{ user.data.vorname }} {{ user.data.nachname }}</h2>
        <h4 class="font-weight-regular"><b class="primary--text text-uppercase">Eltern-Account</b><br>{{user.email}}</h4>
        <v-chip class="mt-3" :color="user.data.activated ? 'success' : 'error'" :to="!user.data.activated ? '/user/aktivierung' : undefined"><font-awesome-icon class="mr-2" :icon="user.data.activated ? 'fa-light fa-check-double' : 'fa-light fa-circle-exclamation'" />{{user.data.activated ? 'Verifiziert' : 'Nicht verifiziert'}} </v-chip>
      </v-col>
      <v-col cols="12" md="10" lg="9" class="my-3">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="10" lg="9">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="9">
            <v-card class="rounded-xl primary--text" color="transparent" flat dark to="/eltern/mein-profil/kinder">
              <v-row>
                <v-col align-self="center">
                  <h2 class="font-weight-light"><font-awesome-icon icon="fa-light fa-children" class="mr-4" />Meine Kinder</h2>
                </v-col>
                <v-col cols="auto" class="mr-4" align-self="center">
                  <font-awesome-icon icon="fa-thin fa-chevron-right" size="lg" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="10" lg="9" class="my-3">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" md="10" lg="9">
            <v-card class="rounded-xl primary--text" color="transparent" flat dark @click="logout()">
              <v-row>
                <v-col align-self="center">
                  <h2 class="font-weight-light"><font-awesome-icon icon="fa-light fa-arrow-right-from-bracket" class="mr-4" />Logout</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import logout from '../../../auth/auth'
import {
  doc,
  updateDoc
} from 'firebase/firestore'
import { getAuth } from '@firebase/auth'


export default {
  name: 'MeinProfil',
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',

    }),
  },
  mounted(){
    console.log(this.user.data)
  },
  methods: {
    logout() {
      logout.logout(getAuth())
    },
    async save(){
      const userdataRef = doc(this.db, "User", this.user.uid);
      await updateDoc(userdataRef, {
        sportarten: this.user.data.sportarten,
      });
    }
  },
}
</script>
